.slick-dots {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  li {
    display: inline-block;
    margin: 0 5px;

    &.slick-active {
      button {
        background: #F5B142;
      }
    }
    button {
      width: 20px;
      height: 20px;
      font-size: 0px;
      color: transparent;
      border: 3px solid #F5B142;
      background: transparent;

      @include respond(small){
        width: 15px;
        height: 15px;
        border: 2px solid #F5B142;
      }
    }
  }
}

.slick-arrow {
  background: transparent;
  color: transparent;
  font-size: 0px;
  width: 18px;
  height: 36px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  z-index: 99;

  &.slick-prev {
    background: transparent url("../svg/slick-left.svg") 50% 50%;
    left: 30px;
    @include respond-down(medium) {
      left: 18px;
    }
  }

  &.slick-next {
    background: transparent url("../svg/slick-right.svg") 50% 50%;
    right: 30px;
    @include respond-down(medium) {
      right: 18px;
    }
  }
}
