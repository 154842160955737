@mixin image-ico($path, $width, $height)
{
  background: {
    color: $t;
    image: url('../images/image-icons/#{$path}.png');
    position: 50% 50%;
    repeat: no-repeat;
  }
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1) {
    background-image: url('../images/image-icons/#{$path}-2x.png');
  }
}

@mixin image-icon($path, $width, $height)
{
  &.#{$path} {
    background-size: contain;
    width: $width;
    height: $height;
    @include image-ico($path, $width, $height);
  }
}

@mixin with-icon($path, $width, $height)
{
  &.#{$path} {
    background-size: $width $height;
    @include image-ico($path, $width, $height);
  }
}



@mixin svg-icon($path, $width, $height)
{
  &.#{$path} {
    background-size: $width $height;
    width: $width;
    height: $height;
    background: {
      color: $t;
      image: url('../svg/#{$path}.svg');
      position: 50% 50%;
      repeat: no-repeat;
    }
  }
}

/*
{icon:raw 'unchecked'}
<i class="svg-icon icon-unchecked"></i>
*/


.image-icon{
  display: inline-block;
  vertical-align: middle;
  @include image-icon('recycling', 62px, 17px);
  @include image-icon('magnet-new', 143px, 148px);
  @include image-icon('repair', 120px, 170px);
  @include image-icon('replace', 295px, 169px);
  @include image-icon('replace-icon', 265px, 281px);

  @include image-icon('atlas', 186px, 43px);
  @include image-icon('cat', 103px, 55px);
  @include image-icon('liebherr', 220px, 44px);
  @include image-icon('senebogen', 242px, 55px);
  @include image-icon('terex', 226px, 39px);
}

.svg-icon {
  display: inline-block;
  vertical-align: middle;

  @include svg-icon('placeholder', 18px, 20px);
  @include svg-icon('placeholder-white', 18px, 20px);
  @include svg-icon('list-white', 24px, 17px);
  @include svg-icon('list', 24px, 17px);
  @include svg-icon('phone-orange', 18px, 18px);
  @include svg-icon('phone-blue', 18px, 18px);
  @include svg-icon('geo-yellow', 13px, 17px);
  @include svg-icon('mail-yellow', 15px, 11px);
  @include svg-icon('down-arrow', 12px, 12px);
  @include svg-icon('download-icon', 25px, 25px);
  @include svg-icon('phone-orange', 18px, 18px);
  @include svg-icon('hamburger', 27px, 17px);
  @include svg-icon('consult__grill', 44px, 48px);
  @include svg-icon('geo-yellow', 13px, 17px);
  @include svg-icon('phone-yellow', 13px, 20px);
  @include svg-icon('icon-insta', 23px, 22px);
  @include svg-icon('icon-fb', 10px, 20px);
  @include svg-icon('icon-youtube', 43px, 17px);
  @include svg-icon('icon-vk', 27px, 15px);
  @include svg-icon('icon-whatsapp', 27px, 15px);
  @include svg-icon('subscribe-icon', 25px, 20px);
  @include svg-icon('icon-linkedin', 20px, 20px);
  @include svg-icon('tests__play-button', 81px, 81px);
  @include svg-icon('call-back-icon', 25px, 25px);
  @include svg-icon('clock', 14px, 14px);
  @include svg-icon('magnet-repair', 143px, 148px);
  @include svg-icon('magnet-repair-white', 161px, 169px);
  @include svg-icon('magnet-old', 143px, 148px);
  @include svg-icon('magnet-old-to-new', 356px, 152px);
  @include svg-icon('arrow-line', 705px, 9px);
  @include svg-icon('arrow-small-down', 10px, 75px);
  @include svg-icon('ic_automatic', 88px, 88px);
  @include svg-icon('ic_automatic_white', 88px, 88px);
  @include svg-icon('ic_car', 88px, 88px);
  @include svg-icon('ic_car_white', 88px, 88px);
  @include svg-icon('ic_machine_building', 88px, 88px);
  @include svg-icon('ic_machine_building_white', 88px, 88px);
  @include svg-icon('ic_metal_base', 98px, 87px);
  @include svg-icon('ic_metal_base_white', 98px, 87px);
  @include svg-icon('ic_metallurgy', 84px, 84px);
  @include svg-icon('ic_metallurgy_white', 84px, 84px);
  @include svg-icon('ic_oil', 86px, 86px);
  @include svg-icon('ic_oil_white', 86px, 86px);
  @include svg-icon('ic_reinforced_concrete', 110px, 74px);
  @include svg-icon('ic_reinforced_concrete_white', 110px, 74px);
  @include svg-icon('ic_woodworking', 98px, 80px);
  @include svg-icon('ic_woodworking_white', 98px, 80px);
  @include svg-icon('ic_download', 26px, 34px);
  @include svg-icon('ic_download_active', 26px, 34px);
  @include svg-icon('ic_pdf', 24px, 22px);

}

.svg-icon.mail-yellow.smalled-bigger{
  background-size: 25px 18px;
  width: 25px;
  height: 18px;
}
