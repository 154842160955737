.categories-list {

  @include respond(small) {
    margin: 0 -8px;
  }

  .products__grid {
    display: grid;
    grid-auto-rows: 400px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px 20px;

    &.smalled {
      grid-template-columns: 1fr 1fr 1fr;
      @include respond-down(middle) {
        grid-auto-rows: 400px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 4px 4px;
      }
      @include respond-down(small) {
        grid-template-columns: 1fr;
        grid-gap: 20px 20px;
        grid-auto-rows: 400px;
      }
      @include respond-down(small){
        .product-card__item.product{
          padding: 30px;

          .product-card__image-holder{
            max-width: 200px;
            max-height: 250px;
            height: auto;
          }
          .product-card__button{
            width: 80%;
            height: 52px;
            line-height: 52px;
            font-size: 15px;
            margin-top: 20px;
          }
        }


      }
    }

    @include respond-down(middle) {
      grid-auto-rows: 400px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 4px 4px;
    }
    @include respond-down(small) {
      grid-template-columns: 50% 50%;
      grid-auto-rows: 250px;

    }
  }


  .grid-item-catalog {
    position: relative;

    .product-card__holder-mask {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      opacity: 0;
      visibility: hidden;
      transition: $default-transition;
      z-index: 2;
      padding: 0px 10px 0px 20px;

      @include respond-down(small){
        display: none;
      }

      ul {
        li {
          color: #fff;
          margin-bottom: 15px;
          padding-left: 20px;
          position: relative;

          &:before {
            content: '—';
            position: absolute;
            left: 0;
            top: 0;
          }

          a {
            color: #fff;
            border-bottom: 1px solid rgba(255, 255, 255, 0.32);
            transition: $default-transition;
            font-size: 15px;

            &:hover {
              border-bottom: transparent;
            }
          }
        }
      }
    }

    &:not(.product) {
      @include respond-up(medium) {
        &:hover {
          .product-card__item {
            &:before {
              background: #012d72eb;
              opacity: 1;
              visibility: visible;
            }

            .product-card__button {
              background: $orange;
              color: #fff;
            }

            .product-card__title {
              color: #fff;
            }

            & + .product-card__holder-mask {
              opacity: 1;
              visibility: visible;
            }
          }

        }
      }

    }

  }

  .product-card__item {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px 18px;
    flex-direction: column;
    background: $white;
    justify-content: space-between;
    transition: $default-transition;
    background-repeat: no-repeat;
    background-position: 50%;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      transition: $default-transition;
      z-index: 1;
    }

    @include respond(small) {
      padding: 10px 8px;
      background-size: auto 40%;
    }


    .product-card__image-holder {
      text-align: center;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      &.vertical {
        max-width: 150px;
        max-height: 200px;

        @include respond(small) {
          max-width: 90px;
          height: 120px;
        }
      }

      &.horizontal {
        max-width: 200px;
        max-height: 150px;
        @include respond(small) {
          max-width: 170px;
          height: 90px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include respond-down(small){
          max-width: 100% !important;
        }
      }
    }


    &.go-to-catalog {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: $orange;
      transition: all ease-out .2s;

      .button {
        position: initial;
        width: 212px;
      }

      &:hover {
        background: $white;

        .button {
          color: $orange;
          border-color: $orange;

          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .consult-form {
    width: 100%;
    height: 100%;
  }

  .product-card__title {
    margin: 0 0 6px 0;
    font-weight: $bold;
    font-size: 16px;
    color: $black;
    text-align: center;
    line-height: 20px;
    text-transform: uppercase;
    position: relative;
    z-index: 2;

    @include respond-down(middle) {
      font-size: 16px;
      line-height: 21px;
    }

    @include respond-down(medium) {
      font-size: 15px;
      line-height: 21px;
    }

    @include respond-down(small) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .product-card__subtitle {
    font-size: 13px;
    color: #767676;
    text-align: center;
    line-height: 15px;
    background: #ffffff;


    @include respond-down(medium) {
      font-size: 11px;
      line-height: 14px;
    }
  }

  .product-card__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

  }

  .product-card__image {
    max-width: none;
  }

  .product-card__button {
    height: 52px;
    line-height: 50px;
    font-weight: $bold;
    white-space: nowrap;
    position: relative;
    z-index: 2;

    @include respond(small) {
      font-size: 12px;
      height: 33px;
      line-height: 33px;
      width: 135px;
      margin: 0 auto;

      &.default{
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.en {
  [data-categories-list-tree-slick] {
    .product-card__item.product {
      @include respond-up(large) {
        border-top: 2px solid $blue;
      }
    }

    .product-card__item-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: auto 65%;

      @include respond(small) {
        padding: 10px 8px;
        background-size: auto 40%;
      }
    }
  }
}

.index-block.products.en .products__grid .product-card__item .product-card__image-holder.horizontal {
  max-width: 100% !important;

  img {
    max-width: 100% !important;
  }
}