.heading-container{
  margin: 31px 0px;
  h1{
    font-size: 27px;
    font-weight: $bold;
    color: black;
    text-align: center;
    text-transform: uppercase;

    &.text-center{
      text-align: center;
    }
  }

  h2{
    font-weight: $bold;
  }

  @include respond-down(medium){
    margin: 20px 0px;

    h1{
      font-size: 18px;
    }
    h2{
      font-size: 18px;
    }
  }
}

h1{
  @extend .styled-header;
}
