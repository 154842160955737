.footer {
  display: flex;
  background: $white;
  padding: 25px 50px;
  @media (min-width: 1480px) and (max-width: 1699px) {
    padding: 25px;
  }
  @media (min-width: 1190px) and (max-width: 1479px) {
      padding: 25px 20px;
  }
  @include respond-down(middle) {
    padding: 15px 15px 25px;
    flex-wrap: wrap;
  }
  @include respond-down(small) {
    align-items: center;
    padding-top: 35px;
    padding-bottom: 20px;
    display: block;
  }

  .footer-wrapper-all{
    display: flex;

    @include respond-up(large) {
      align-items: flex-end;
    }
    @include respond-down(middle) {
      flex-direction: column;
    }
    @include respond-down(small) {
      align-items: center;
      display: block;
    }
  }

  &__left {
    display: flex;
    flex-basis: 25%;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    @include respond-up(large) {
      margin-right: 100px;
    }
    @media (max-width: 1479px) {
      flex-wrap: initial;
    }
    @include respond-down(middle) {
      margin-bottom: 30px;
      max-width: initial;
      flex: 1 1 200px;
      padding: 0 10px 0 0;
    }
    @include respond-down(small) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .header__logo {
      .logo-image {
        margin-bottom: 15px;
      }
      @include respond(small) {
        padding-right: 0px;
        .logo-image {
          margin: 0 auto 15px;
        }
      }
      @media(max-width: 1189px) {
        padding: 0;
      }
      @media(max-width: 784px) {
        flex: 0 0 12%;
        padding: 0;
      }
    }
  }

  &__middle {
    display: flex;
    flex: 1 1 auto;

    &.hide_1000{
      @media(max-width: 1189px) {
        display: none;
      }
    }

    &.no-flex{
      display: block;

      .footer__middle_wrapper{
        display: flex;
        justify-content: space-between;
        @include respond-up(large) {
          justify-content: space-between;
          max-width: 800px;
          margin-right: 50px;
        }
      }
    }

    @include respond-down(middle) {
      order: 3;
      justify-content: space-between;
      width: 100%;
      margin: 10px auto 0;
      padding-bottom: 20px;
    }
    @include respond-down(small){
      display: block;
    }
    @media(min-width: 1190px) {
      margin-top: 35px;
    }
  }


  &__list {
    @include respond-up(large) {
      &:not(:last-child) {
        margin-right: 50px;
      }
      max-width: 373px;
    }
    @include respond-down(middle) {
      flex: 0 1 auto;
      &:not(:last-child) {
        margin-right: 10px;
      }
      max-width: 373px;
    }

    &-item {
      &:first-child {
        margin: 0 0 20px 0;
        color: $black;
      }

      @include textBold();
      text-transform: uppercase;
    }

    &-link {
      @include textBold();
      color: $blue;
      text-transform: uppercase;
    }
  }

  &__subscribe {
    display: flex;
    position: relative;
    flex-direction: column;

    @include respond-down(middle) {
      flex-direction: row;
      align-items: center;
    }
    @include respond-down(small) {
      display: inline-block;
      width: 100%;
    }
  }

  .footer__input {
    @include respond-down(middle) {
      width: 291px;
      flex: 0 0 291px;
    }
    @include respond-down(small) {
      order: 1;
      width: 100%;
      flex: initial;
      margin: 0 auto;
    }
  }

  &__check {
    margin: 0 15px 0 0;
    @include checkboxStyle();
  }


  &__check-label {
    margin: 6px 0 0 0;
    font-size: 12px;
    color: $black;
    line-height: 16px;
    @include respond(small) {
      font-size: 10px;
      margin: 3px 0px 0px 0px;
      text-align: left;
      a {
        margin-left: 2px;
      }
    }

    a {
      color: $blue;
      transition: all ease-out .2s;

      &:hover {
        border-bottom-color: $t;
      }
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    order: 2;
    .header__address-line {
      margin-bottom: 10px;
    }
    @media(min-width:1191px) {
      order: 2;
    }
  }
  .header__address-line {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: #001e4d;
    line-height: calc(22 / 14);
    text-align: left;
    @include respond-down(small){
      justify-content: center;
    }

    &.before-phone {
      margin-bottom: 3px;
    }

    &.after-phone {
      .title {
        margin-left: 0;
      }
    }

    .title{
      margin-left: 10px;
      font-size: 13px;
      line-height: 1;
      @include respond-down(small){
        margin-left: 0px;
        max-width: 200px;
      }
    }
    .svg-icon{
      flex: 0 0 auto;
      align-self: flex-start;
      @include respond-down(small){
        margin-right: 10px;
      }
    }
  }

  &__copy {
    a {
      text-decoration: underline;
      transition: all 0.3s ease-in;
      &:hover {
        color: $orange;
      }
    }
    @include respond-up(large) {
      margin-bottom: 10px;
    }
    @include respond-down(middle) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      order: 3;
    }
    @include respond(small) {
      display: none;
    }
    @media(min-width:1191px) {
      order: 3;
    }
    &.en {
      white-space: initial;
      color: #001e4d;
      line-height: calc(22 / 14);
      font-size: 14px;
    }
  }
  // .footer__copy-mobile
  &__copy-mobile {
    display: none;

    @media(max-width:783px) {
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        text-decoration: underline;
        transition: all 0.3s ease-in;
        &:hover {
          color: $orange;
        }
      }
    }
    &.en {
      color: #001e4d;
      line-height: calc(22 / 14);
      font-size: 14px;
    }
  }

  &__contacts {
    @include respond-down(middle) {
      padding: 0 10px;
      order: 1;
    }
    @include respond(small) {
      margin: 5px 0 0 0;
      padding: 0;
    }
  }
  // .footer__right
  &__right {
    @media(max-width:783px) {
      margin: 0 auto;
    }
    @media(min-width: 784px) {
      margin-top: 35px;
    }
    max-width: 440px;

    &.mobile_margin{
      margin-top: 2rem;

      @include respond-up(medium){
        margin-top: 0px;
      }
    }
  }
  // .footer__right-top
  &__right-top {
    display: flex;
  }
  // .footer__info
  &__info {
    flex: 1 1 auto;
    margin-bottom: 18px;
    color: #161245;
  }
  // .footer__name
  &__name {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: calc(18 / 16 * 100%);
    font-weight: 700;
  }
  // .footer__text
  &__text {
    font-size: 14px;
    line-height: calc(17 / 14 * 100%);
  }
  // .footer__image
  &__image {
    @media(min-width:784px) {
      flex: 0 0 150px;
    }
    text-align: center;
  }
  // .footer__form
  &__form {
    margin-bottom: 18px;
  }

  &__social_wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-top: 2.5rem;
    width: 100%;
  }

  // .footer__social
  &__social {
    @media(max-width:783px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;
    }
  }
  // .footer__success
  &__success {
    display: none;
  }
}

.form-footer {
  position: relative;
  .form-footer__success {
    display: none;
  }
  &.success .form-footer__success {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: 0.8);
    z-index: 2;
  }
  // .form-footer__fieldset
  &__fieldset {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
  }
  // .form-footer__label
  &__label {
    flex: 0 1 calc(100% - 150px);
    input[type="text"] {
      height: 36px;
      background: transparent;
      border-radius: 0;
      border-bottom: 2px solid #F5B142;
      padding: 0 20px 0 0;
      width: 100%;
    }
  }
  // .form-footer__btn
  &__btn {
    flex: 0 0 150px;
    height: 36px;
    border-radius: 0;
    background: #F5B142;
    font-size: 12px;
    line-height: calc(15 / 12 * 100%);
    font-weight: 700;
    color: #fff;
    text-transform: none;
    transition: all 0.3s ease-in;

    &.rounded{
      position: relative;
      border-radius: 2rem;
      text-transform: uppercase;
      color: #000;

      &:hover {
        color:#fff;
      }

      &::after{
        content: '';
        width: 90%;
        height: 2px;
        background-color: #f6b142;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &:hover {
      color:#161245;
    }
  }
  // .form-footer__argee
  &__argee {
    width: 100%;
    position: relative;
    display: flex;
    font-size: 12px;
    line-height: 12px;
    color: #000;
    cursor: pointer;
    a {
      color: #F5B142;
      transition: all 0.3s ease-in;
      &:hover {
        text-decoration: underline;
      }
    }

    span {
      //display: flex;
      //align-items: center;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 17px;
        height: 17px;
        border: 1px solid #161245;
        border-radius: 50%;
      }
    }
    &._white {
      color: #fff;
      span {
        border-color: #fff;
        &::before {
          border-color: #fff;
        }
      }
      .form-footer__checkbox:checked + span::after {
        background: #fff;
      }
    }
    &._center {
      align-items: center;
    }
  }
  // .form-footer__checkbox
  &__checkbox {
    appearance: none;
    width: 19px;
    height: 19px;
    margin: 0px 16px 0px 0px;
    &:checked + span {
      &::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 13px;
        height: 13px;
        background: #161245;
        border-radius: 50%;
      }
    }
  }
}

.social-footer {
  // .social-footer__title
  &__title {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: calc(17 / 14 * 100%);
    color: #161245;
  }
  // .social-footer__box
  &__box {
    display: flex;
    margin: 0 -10px;
    align-items: center;
  }
  // .social-footer__link
  &__link {
    padding: 0 10px;
    transition: all 0.3s ease-in;
    &:hover {
      color: #F5B142;
    }
  }
}
