$default-input-height: 52px;

.button, button {
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0;
  //transition: $default-transition;
  border: none;
  background: $orange;
  font-size: 14px;
  cursor: pointer;
  font-weight: $bold;
  text-transform: uppercase;
  border-radius: 26px;
  color: $black;
  vertical-align: middle;

  span.title, span.icon {
    display: inline-block;
    vertical-align: middle;
  }

  &.bordered {
    background: $t;
    border: 1px solid $orange;
    transition: all ease-out .2s;

    &.height {
      line-height: 50px;
    }

    &:hover {
      background: $orange;
    }
  }

  &.filled {
    background: $orange;
    border: 1px solid $orange;
    transition: all ease-out .2s;

    &:hover {
      background: $blue;
      color: #ffffff;
      border-color: $blue;
    }

    &.blue-hover {
      &:hover {
        background: transparent;
        border: 1px solid $orange;
        color: inherit;
      }
    }

    &.white-color-hover {
      &:hover {
        color: #ffffff;
      }
    }
  }

  &.back-button-mobile {
    zoom: 2;

  }

  &.expand, &.wide {
    width: 100%;
  }

  &.width {
    width: 302px;
  }

  &.height {
    height: $default-input-height;
    line-height: $default-input-height;
  }

  &.white {
    background: $t;
    border-color: $white;
    color: $white;

    &:hover {

    }
  }
}

form {
  label {
    font-size: 12px;
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    font-size: 13px;

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"]', 'input[type="tel"]';

  #{$inputs}, textarea, select {
    border: 0;
    background: $greyec;
    border-radius: 26px;
    box-shadow: none;
    margin: 0;
    padding: 0 27px;
    font-size: 14px;
    width: 100%;
    display: block;
  }

  #{$inputs}, select {
    height: $default-input-height;
  }

  textarea {
    padding: 5px 10px;
  }

}

.policy {
  color: #ffffff;
  font-size: 12px;
  margin-top: 10px;

  &.black {
    color: black;
  }

  a {
    color: #F5B142;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
}

[data-ajax-form="ConsultForm"] {
  .policy {
    a {
      color: #F5B142;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.success-form-holder {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 30, 77, 0.6);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: $default-transition;

  .wrap {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    padding: 0px 10px 0px 0px;
    transform: translateX(-50%) translateY(-50%);
    background: #ffffff;
    border-bottom: 6px solid #F5B142;
    @media (min-width:480px) {
      width: 480px;
      padding: 40px 30px;
    }
  }

  &.success {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    .success-wrapper {
      width: 100%;
      height: 160px;
    }
  }

}

.consult-form {
  background: $blue;
  color: #ffffff;
  padding: 34px 20px;

  .consult__title {
    font-size: 21px;
    font-weight: $bold;
    text-align: center;
    margin-bottom: 30px;
  }

  .svg-icon {
    float: left;
  }

  .consult__text {
    margin-left: 40px;
  }

  .consult__form-group {
    flex-direction: column;


    .consult__input {
      margin-bottom: 20px;
      position: relative;

      .errors {
        position: absolute;
        bottom: -20px;
        text-align: center;
        left: 0;
        right: 0;
      }

      &.email-phone {
        width: 100%;
      }
    }

    .consult__button {
      width: 100%;
    }
  }
}


.modal__content {
  form {
    .policy {
      ul {
        position: static;
      }
    }
  }
}

form {
  .policy {
    margin-top: 5px;

    &.center {
      text-align: center;
    }

    &.link-yellow {
      label {
        a {
          color: $orange;
        }
      }

      ul {
        position: static;
        margin-top: 10px;
      }
    }

    ul {
      position: absolute;
    }

    label {
      position: relative;
      border: 0;
      background: 0;
      outline: none;
      box-shadow: none;
      padding-left: 25px;
      transform: translateX(-2px) translateY(2px);

      &:before {
        background: $white;
        content: "";
        opacity: 1;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        border: 1px solid $lightblue;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    input[type=checkbox] {
      display: none;

      &:checked {
        & + label:before {
          background-repeat: no-repeat;
          background-size: 9px 7px;
          background-position: center center;
          background-image: url('../svg/galka.svg');
        }
      }
    }
  }

}


.index-block.consult {
  form.form-with-file-input.consult__form {
    justify-content: center;
    flex: 1 1 auto;
    width: 100%;

    .consult__policy {
      text-align: center;
      bottom: -30px;

      @include respond-down(medium){
        position: static;
        margin: 20px 0px;
      }
    }

    .form-centered-wrapper {
      position: relative;
      width: 100%;
      @include respond-up(middle){
        display: flex;
        justify-content: center;
        flex: 100%;
        width: 100%;
      }

      @include respond(medium){
        display: block;
      }
    }

    .left {
      .consult__text_girl {
        padding: 0px;
        max-width: 200px;
      }
    }

    .center {
      .consult__policy{
        @include respond(middle){
          position: static;
          margin: 20px 0px;
        }
      }
    }

    .right {
      @include respond(middle){
        text-align: center;
      }

      @include respond(medium){
        text-align: center;
      }
      .consult__button {
        margin-left: 0px;
      }
    }

    .consult__input.file {
      border-radius: 26px;
      padding: 16px 5px 16px 27px;
      background: #ffffff;
    }

    .consult__form-group{
      align-items: baseline;
      @include respond(medium){
        display: block;
        .consult__form-group{
          display: flex;
        }
      }
      @media (max-width:999px) {
        .consult__input:not(input) {
          margin: 0px 0px 20px 0px;
        }
      }
      @media (min-width: 1000px) {
        .consult__input:not(input) {
          margin: 0px 15px 0px 0px;
        }
      }
    }


    input[type=file] {
      background: transparent;
    }

    input::placeholder {
      color: $grey7c;
      opacity: 1;
    }
  }
}
.iti--allow-dropdown {
  width: 100%;
}

.iti-mobile .iti__country-list,
.iti__country-list {
  width: calc(100% - 20px);
  margin: 0 auto;
}

[data-phone-container] {
  position: relative;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
}
