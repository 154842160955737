.profits-block {

  .title {
    margin: 0 0 40px;
    font-weight: $bold;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    text-transform: uppercase;
    color: $black;
  }

  .pros {
    display: flex;
    justify-content: space-between;
    @include respond-down(middle) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @include respond(small) {
      display: block;
    }

    .pros-item {

      @include respond-down(middle) {
        flex: 0 0 calc(100% / 3);
        &:nth-child(n+4) {
          margin-top: 45px;
        }
      }

      @include respond(small) {
        margin-bottom: 25px;
        &:last-child{
          margin-bottom: 0px;
        }
        &:after{
          display: table;
          width: 100%;
          clear: both;
          content: '';
        }
      }

      .icon {
        text-align: center;
        width: 90px;
        @include respond-up(small){
          height: 70px;
          display: flex;
          img{
            object-fit: contain;
          }
        }


        @include respond(small) {
          float: left;
          width: 50px;
        }
      }

      .text {
        max-width: 227px;
        margin: 22px 0 0;
        font-weight: $bold;
        font-size: 16px;
        line-height: 19px;
        color: $black;
        @include respond(small) {
          float: left;
          width: calc(100% - 70px);
          margin-left: 20px;
          max-width: 100%;
          font-size: 14px;
          margin-top: 0px;
        }
      }
    }
  }
}
