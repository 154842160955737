.page-root {
  padding-top: 80px;

  @include respond-down(large) {
    padding-top: 75px;
  }

  @include respond(middle) {
    padding-top: $headerHeightMiddle;
  }
  @include respond(medium) {
    padding-top: $headerHeightMedium;
  }
  @include respond(small) {
    padding-top: $headerHeightSmall;
  }

}

html, body {

  @include respond-up(medium) {
    height: 100%;
  }
}

.root {

  @include respond-up(medium) {
    height: 100%;
  }

  @include respond-up(medium) {
    display: flex;
    flex-direction: column;

    .page {
      flex: 1 0 auto;
    }

    #footer {
      flex-shrink: 0;
      justify-self: flex-end;
    }
  }

}

.content-stiky {
  .row.flex {
    display: flex;
    margin: 0px -11px;

    @include respond-down(medium) {
      display: block;
    }

    .grid-col{
      padding: 0 11px;

    }

    .stiky-parent {
      position: relative;
    }

    .content-stiky {
      position: sticky;
      top: calc(#{$headerHeight} + 21px);

      @include respond-down(large) {
        top: calc(#{$headerHeightMiddle} + 21px);
      }

      @include respond-down(middle) {
        top: calc(#{$headerHeightMiddle} + 21px);
      }

      @include respond-down(medium) {
        position: static;
      }

      &.no-stiky{
        position: initial;

        .aside-product{
          .aside-wrapper{
            background-color: $blue;
          }
        }
      }

    }
  }

}

.stop-mobile-layout{
  .product-page{

    .blocks-container{
      .content-raw{
        .table-full-width{
          display: none !important;
        }

        table{
          margin: 30px 0px;
          min-width: 100% !important;
          table-layout: auto;
          width: 100%;
        }

        h3{
          margin-top: 30px;
        }
      }
    }
  }

}

.title-second {
  display: flex;
  position: relative;
  justify-content: center;
  margin: 0 auto 20px;
  font-weight: $bold;
  font-size: 30px;
  line-height: 37px;
  color: $black;
  text-align: center;
  align-items: center;
  @include respond-down(middle) {
    min-height: 55px;
    margin: 0 auto 10px;
    font-size: 24px;
    line-height: 29px;
  }
  @include respond-down(small) {
    margin: 0 auto 25px;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    justify-content: center;
    min-height: 0;
  }
}

.block-content {
  a {
    text-decoration: underline;
    transition: all .3s ease;

    &:hover {
      color: #f5b142;
    }
  }
}

.ru {
  .content-stiky {
    .row.flex {
      .content-stiky {
        top: calc(#{$headerHeight} + 75px );

        @include respond-down(large) {
          top: calc(#{$headerHeightMiddle} + 75px);
        }

        @include respond-down(middle) {
          top: calc(#{$headerHeightMiddle} + 75px);
        }
      }
    }
  }
}

