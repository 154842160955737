.breadcrumb-container {
  margin-top: 26px;

  @include respond-down(medium) {
    margin-top: 20px;
  }




  .breadcrumbs-block {
    .breadcrumbs-list {
      hyphens: auto;
      font-size: 12px;
      line-height: 1.5;

      li {
        display: inline;
        vertical-align: middle;
        font-weight: $bold;
        color: #6F6F6F;
        position: relative;
        text-transform: uppercase;
        margin-bottom: 15px;

        @include respond-down(medium) {
          font-size: 10px;
        }

        @include respond-down(small) {
          font-size: 9px;
        }

        &.with-dropper{
          line-height: 1;
          li{
            margin: 0px;
          }
          .svg-icon{
            opacity: 0.8;
            margin-left: 3px;
            @include respond-down(medium){
              display: none;
            }
          }
        }

        a {
          transition: all 0.4s;
          font-size: 12px;
          color: #6F6F6F;
          display: inline-block;

          @include respond-down(medium) {
            font-size: 10px;
          }

          @include respond-down(small) {
            font-size: 9px;
          }
        }

        &.delimiter {
          margin: 0 7px;
          display: inline-block;
        }

        &:hover{

          .inner-list{
            transition-delay: 0.5s;
            transform: translateY(0px);
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
          }
        }

        .inner-list{
          transform: translateY(20px);
          transition: $default-transition;
          position: absolute;
          background: #ffffff;
          padding: 20px 15px 10px 15px;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          z-index: 199;
          left: 0;
          width: 350px;

          @include respond-down(medium){
            display: none;
          }


          li{
            display: block;
            margin-bottom: 10px;
            a{
              display: inline-block;
            }
          }
        }
      }
    }
  }


}

