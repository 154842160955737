article {
  &, p, li {
    font-size: 14px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

}


.article-content{
  line-height: 1.3 !important;
  font-size: 15px;
  * {
    font-family: $font !important;
  }
  .cover-image{
    float: left;
    max-width: 500px;
    margin-right: 20px;
    @include respond-down(small){
      float: none;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .current{
    margin-top: 50px;

    @include respond-down(small){
      position: relative;
    }
  }
  h2{
    @include respond-property(font-size, 18px, 18px, 18px, 20px, 23px, 25px);
    font-family: $h-font !important;
    font-weight: normal !important;
    margin-bottom: 20px;
    strong{
      font-weight: normal;
    }
  }
  ul{
    li{
      margin-bottom: 8px;
      &:before{
        content: '– ';
        color: $orange;
      }
    }
  }

  .page-title{
    @include respond-property(font-size, 18px, 19px, 20px, 25px, 28px, 28px);
    font-weight: bold !important;
  }

  .other-news{
    text-align: center;
    margin-bottom: 30px;
  }

  table{
    width: 100% !important;
    margin: 30px 0px;
  }

  p, li {
    font-size: 16px;
  }

  a:not(.news__read-more) {
    text-decoration: underline;

    &:hover{
      color: #ff9e00;
    }
  }
}
