@include respond-down(middle){
  .wrapper-lang-menu{
    padding: 20px;
    ul{
      display: flex;
      width: 100%;
      justify-content: space-between;
      .link-site {
        .svg-icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}

.eng-header {
  .centered-header-link-down-load{
    @include respond-down(middle){
      flex: 0 0 auto;
    }
  }
  .lang-list-holder{
    position: relative;
    margin-left: 20px;
    @include respond-down(large){
      margin: 0px 35px;
    }
    @include respond-down(middle){
      margin: 0px 72px 0px 35px;
    }

    @include respond-down(small){
      margin: 0px;
      position: static;
    }
    .dropdown-holder{
      cursor: pointer;
      display: flex;
      height: 85px;
      align-items: center;

      @include respond-down(small){
        height: 75px;
      }

      .title-current-wrapper{
        position: relative;
        &:after{
          content: "";
          position: absolute;
          margin-left: 4px;
          width: 10px;
          height: 6px;
          top: 50%;
          transform: translateY(-50%);
          background: transparent url('../svg/arrow-menu-top-blue.svg') 50% 50% no-repeat;
          display: inline-block;
          right: -20px;

          @include respond-down(small){
            right: auto;
            left: -20px;
          }
        }

      }
      &:hover{
        .wrapper-lang{
          opacity: 1;
          visibility: visible;

          @include respond-down(small){
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      &.open {
        .wrapper-lang {
          @include respond-down(small){
            opacity: 1;
            visibility: visible;
          }
        }
      }

      @include respond-down(small){
        .title{
          display: none;
        }
      }
    }

    .title{
      font-size: 14px;
      font-weight: 500;
      color: #061E4D;
      @include respond(medium){
        font-size: 14px;
      }
    }
    .wrapper-lang{
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      left: -15px;
      opacity: 0;
      visibility: hidden;
      border-top: 1px solid #B5BECB;
      transition: $default-transition;
      border-top: 3px solid $orange;

      @include respond-down(small){
        left: 0;
        right: 0;
      }
    }
    .lang-list {
      display: flex;
      flex-direction: column;
      background: #fff;
      width: 200px;

      @include respond-down(small){
        width: auto;

        .title{
          display: block;
        }
      }

      .lang-item{
        height: 54px;
        border-bottom: 1px solid #B5BECB;
        display: flex;
        transition: all 0.3s ease-in;
        .link-site{
          display: flex;
          width: 100%;
          height: 100%;
          padding: 15px 15px;
        }
        &:hover {
          background: #b5becb;
        }
      }
    }

    .icon{
      width: 30px;
      margin-right: 15px;
      @include respond-down(small){
        width: 35px;
      }
    }
    .svg-icon {
      width: 30px;
      height: 20px;

      @include respond-down(large){
        width: 25px;
        height: 18px;
      }
      @include respond-down(small){
        width: 35px;
        height: 25px;
      }
    }
  }
}

.eng-header.header {
  .header__wa-mobile {
    svg {
      width: 25px;
      height: 25px;
    }
  } 
}